@import './styles/index.css';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: var(--spacing-2);

  background-color: white;
}

.header h2 {
  max-width: var(--max-width);
  width: 100%;
  margin: auto;
  font-size: 1.5rem;
  margin-bottom: 0;
}

.header h2 a {
  text-decoration: none;
}

.nav ul {
  display: flex;
  padding: 0;
  list-style: none;
  gap: var(--spacing-2);
}

.content {
  max-width: var(--max-width);
  margin: auto;
  padding: 0 var(--spacing-2);
}

.footer {
  padding: var(--spacing-2);

  background-color: white;
}

.footerWrapper {
  max-width: var(--max-width);
  margin: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.footer ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-1);
  padding: 0;
}