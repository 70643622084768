@import './styles/index.css';

.wrapper {
  display: grid;
  grid-template-columns: 240px 1fr;
  gap: var(--spacing-2);
  margin: var(--spacing-3) 0;
}

@media screen and (max-width: 600px) {
  .wrapper {
    display: block;
  }
}

/* Cards */  
.card {
  padding: var(--spacing-1);
  text-align: center;
  position: relative;
  border: 1px solid var(--wheat);
  outline: 2px solid transparent;
  outline-offset: 1px;
  transition: outline-color 0.2s ease;
  background-color: white;
}
  
.card:focus,
.card:hover {
  outline-color: var(--wheat);
}
  
.card:hover img {
  transition: opacity 0.2s ease-in-out;
}
  
.card:hover img {
  opacity: .8;
}
  
.link {
  text-decoration: none;
}

.copy {
  padding: 8px;
}

.designer {
  font-size: var(--font-small);
  color: var(--charcoal);
  margin-top: 8px;
}

/* Filter */
.filter {
  margin-bottom: var(--spacing-2);
}
.filter legend {
  font-weight: 500;
  margin-bottom: var(--spacing-2);
}
.filter label {
  display: block;
}

/* Pagination */
.pagination ul {
  justify-content: center;
}