@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;700&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');
@import './reset.css';
@import './tokens.css';


body {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: var(--spacing-1);

  letter-spacing: .5px;
  color: var(--charcoal);
  background-color: var(--snow);
}

h1,
h2,
h3 {
  font-family: 'Playfair Display', serif;
  font-weight: 600;
  letter-spacing: -1px;
  margin-bottom: var(--spacing-2);
  line-height: auto;
}

h1 {
  font-size: 3.6rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 1.8rem;
}

p {
  margin: auto;
  max-width: 70ch;
}

a {
  color: var(--wheat);
}

img {
  width: 100%;
}


/* Utility Classes */
.visuallyHidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}


/* MUI Overrides - Remove when switching component library */
span {
    font-weight: 300 !important;
}
